import './App.css';
import WelcomeCard from './components/WelcomeCard';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <WelcomeCard/>
        <p>
          More coming soon...
        </p>
      </header>
    </div>
  );
}

export default App;
